.n2-navbar{
    position: sticky;
  top: 0; 
  z-index: 1000; 
    background-color: #1a1a1a; /* Dark background */
    color: #ffffff; /* White text */
}
.n2-nav-menu{
    background-color: #333;
    width: 25rem;
}
.n2-nav-menu li {   
    position: relative;
    transition: color 0.3s ease;
    padding: 2px 0 2px 0;
}
.n2-nav-menu li a{   
    padding: 5px 10px 5px 10px;
}
.n2-nav-menu li::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    height: 2px; 
    transform: translateX(-50%);
    background-color: white; 
    transition: width 0.3s ease, left 0.3s ease;

}

.n2-nav-menu li:hover::before,
.n2-nav-menu li.active::before {
    width: 20px;
    left: 50%;
    transform: translateX(-50%);
}
.n2-nav-menu li:hover::before{
    animation: blink .7s infinite; 
    box-shadow:0px 6px 16px 9px rgba(252, 252, 252, 0.108);
}
.n2-nav-menu li.active::before {
    box-shadow:0px 10px 25px 15px rgba(252, 252, 252, 0.25);
    animation:none;
}
@keyframes blink {
    0%, 100% {
        opacity: 1; /* Fully visible */
    }
    50% {
        opacity: 0; /* Fully transparent */
    }
}
/* .n2-nav-menu li:hover,
.n2-nav-menu li.active{
    color:#1089FF;
} */
.n2-nav-menu li.active{
    background-color: #434343;
    border-radius: 25px;
}
  
  button:hover {
    background-color: #555555; /* Change on hover */
  }


  /* Button css */
.downloadBtn {
    border: 2px solid #686868;
    padding: 4px 0px 4px 25px !important;
    font-size: 0.8rem;
    transition: all .8s ease;
    position: relative; 
    overflow: hidden; 
    background-color: #2b2b2b;
    border-radius: 25px;
    color: white;
    font-weight: bold;
  }
  
  .downloadBtn:hover {
    color: white;
    background-color: #1089FF;
    border-color: #1089FF;
  }
  
  .downloadBtn .icon {
    position: absolute; 
    top: 50%;
    transform: translateY(-50%);
    left:10px;
  }
  
  .downloadBtn:hover .icon {
    animation: downloadAnimation 0.8s forwards;
  }
  
  @keyframes downloadAnimation {
    0% {
        top: 50%;
        transform: translateY(-50%);
        opacity: 1;
    }
    40% {
        top: 100%;
        opacity: 0;
    }    
    60% {
        top: 0%;
        opacity: 0;
    }
    100% {
        top: 50%;
        transform: translateY(-50%);
        opacity: 1;
    }
  }