.cursor{
    animation-name: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.displayText{
    font-size: 2rem;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }

  @media (max-width: 900px) {
    .displayText{
        font-size: 1.5rem;
    }
  }
