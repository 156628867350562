@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofadi+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lumanosimo&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    overflow: auto;
}
html{
    scroll-behavior: smooth;
}

.page-container{
    padding: 1rem 5rem;
}

 /*Loading overlay*/
.overlay{
    position: absolute;
    background-color: rgb(255, 255, 255);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    display: none;
}

@media (max-width: 900px) {
    .page-container {
        padding: .5rem 2.5rem;
    }
}
@media (max-width: 610px) {
    .page-container {
        padding: .2rem 1.5rem;
    }
}